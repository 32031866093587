import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { CartService, OrderData } from '@services/cart.service';
import { DeliveryType } from '@models/delivery-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
	AddressSuggestionResponse,
	ApishipDeliveryRequest,
	ApishipPickUpPointsResponse,
	DeliveryInfoResponse,
	OrderCreatedResponse,
	OrderRequest,
	PickUpPointResponse,
} from '@models/generated/api';
import { InvoiceStatus } from '@models/telegram/invoice-status';
import { FormUtils } from '@utils/form-utils';
import { DadataService } from '@services/dadata.service';
import { ShopService } from '@services/shop.service';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { TranslationService } from '@services/translation.service';
import { YaGeocoderService, YaReadyEvent } from 'angular8-yandex-maps';
import { LoadingService } from '@services/loading.service';
import { DestroyService } from '@services/destroy.service';
import { TranslocoService } from '@ngneat/transloco';
import { PlacemarkConstructor } from '@utils/map';
import { ConnectedPosition } from '@angular/cdk/overlay';
import productDeliveryType = ApishipDeliveryRequest.productDeliveryType;
import { Form, FormGroup, Validators } from '@angular/forms';
import { environment } from '@environments/environment';

@Component({
	selector: 'tk-delivery',
	templateUrl: './delivery.component.html',
	styleUrls: ['./delivery.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
})
export class DeliveryComponent {
	paymentLink: string | null = null;
	cart$ = this.cartService.productsInCart$;
	shopInfo: any = null;

	loadingProviders$ = new BehaviorSubject<boolean>(false);
	loadingPickupPoints$ = new BehaviorSubject<boolean>(false);
	loadingPromocode$ = new BehaviorSubject<boolean>(false);
	showBtn = false;
	isApishipLoading = false;
	form = this.cartService.form;

	fiasList: AddressSuggestionResponse[] | null = null;
	fiasSelected: AddressSuggestionResponse | null = null;

	shopId = this.route.parent?.snapshot.params['shopId'];
	DeliveryType = DeliveryType;
	delivery?: DeliveryInfoResponse;
	deliveryErr: boolean = false;
	pickupPoints = new BehaviorSubject<PickUpPointResponse[] | null>(null);
	hidePickup: boolean = false;
	cityErr: boolean = false;
	mapErr: boolean = false;
	tarrifErr: boolean = false;

	apishipPoints: ApishipPickUpPointsResponse[] | null = null;
	apishipCenter = new BehaviorSubject<number[] | null>(null);

	finalPrice = new BehaviorSubject<number | null>(null);

	map?: ymaps.Map;
	mapPoints: PlacemarkConstructor[] = [];
	updatingAddress: boolean = false;

	fields: any = new BehaviorSubject([]);

	overlayPositions: ConnectedPosition[] = [
		{
			originX: 'start',
			originY: 'bottom',
			overlayX: 'start',
			overlayY: 'bottom',
		},
	];

	isPromocodeActive: boolean = false;
	promocodeErrorMessage: string | null = null;

	constructor(
		private location: Location,
		private route: ActivatedRoute,
		private router: Router,
		public cartService: CartService,
		private cdr: ChangeDetectorRef,
		private zone: NgZone,
		private dadataService: DadataService,
		public shopService: ShopService,
		private translationService: TranslationService,
		private yaGeocoderService: YaGeocoderService,
		public loading$: LoadingService,
		private translocoService: TranslocoService,
		private destroy$: DestroyService,
	) {
		document.body.firstElementChild!.setAttribute('tabIndex', '1');

		this.shopService.getShopInfo(this.shopId).pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				this.shopInfo = res;
				if (this.hasPaymentType(OrderCreatedResponse.paymentType.TELEGRAM)) {
					this.form.controls.paymentType.setValue(OrderCreatedResponse.paymentType.TELEGRAM);
					return;
				}
				if (this.hasPaymentType(OrderCreatedResponse.paymentType.TINKOFF)) {
					this.form.controls.paymentType.setValue(OrderCreatedResponse.paymentType.TINKOFF);
					return;
				}
				if (this.isHasCustomPayment) {
					this.form.controls.paymentType.setValue(this.shopInfo.paymentInfo.customPayments[0].id);
					return;
				}
			},
			error: err => {

			}
		})

		this.shopService.getShopOrderFields(this.shopId).pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				if (res) {
					this.fields.next(res);
					if (!res.find(i => i.name === 'Фамилия')) {
						(<FormGroup>this.form.get('receiver'))!.removeControl('lastName');
					}
					if (!res.find(i => i.name === 'Отчество')) {
						(<FormGroup>this.form.get('receiver'))!.removeControl('patronymic');
					}
					if (!res.find(i => i.name === 'Телефон')) {
						(<FormGroup>this.form.get('receiver'))!.removeControl('phone');
					}
					if (!res.find(i => i.name === 'Email')) {
						(<FormGroup>this.form.get('receiver'))!.removeControl('email');
					}
					if (!res.find(i => i.name === 'Промокод')) {
						(<FormGroup>this.form)!.removeControl('promocodeId');
					}
					if (!res.find(i => i.name === 'Комментарии к заказу')) {
						(<FormGroup>this.form)!.removeControl('comment');
					}
				}
				this.cdr.markForCheck();
				this.form.updateValueAndValidity();
			},
			error: err => {
				console.log(err.error?.message);
			},
		});

		if (shopService.isShopDigital()) {
			return;
		}

		this.cartService.getShopDeliveryOptions(this.shopId).pipe(takeUntil(this.destroy$)).subscribe((res: DeliveryInfoResponse) => {
			this.delivery = res;

			if (
				this.cartService.cityForm.controls.selectedCity.value?.fiasId &&
				this.checkDeliveryOption(DeliveryType.Apiship)
			) {
				this.isApishipLoading = true;
				this.loading$.next(true);
				this.loadingProviders$.next(true);
				this.cartService
					.getApishipProviders(this.shopId, this.cartService.cityForm.controls.selectedCity.value.fiasId)
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: res => {
							this.cartService.apishipProviders.next(res);

							if (res.providers?.length && this.delivery?.deliveryOptions?.length === 1) {
								this.form.controls.deliveryOption.setValue(this.cartService.apishipProviders.value!.providers![0].providerKey!);
							}
							this.isApishipLoading = false;
							this.loading$.next(false);
							this.loadingProviders$.next(false);
							this.isNoDelivery();
							if (
								this.checkDeliveryOption(DeliveryType.Apiship) &&
								this.isSelectedDeliveryOptionApiship() &&
								this.cartService.selectedCity?.fiasId
							) {
								this.cartService
									.getApishipPickupPoints(
										this.shopId,
										this.form.controls.deliveryOption.value!,
										this.cartService.selectedCity.fiasId,
									)
									.pipe(takeUntil(this.destroy$))
									.subscribe({
										next: data => {
											this.apishipPoints = data;
											this.yaGeocoderService
												.geocode(
													this.cartService.selectedCity.address ??
														this.cartService.selectedCity.addressShort,
												)
												.pipe(takeUntil(this.destroy$))
												.subscribe({
													next: (result: any) => {
														const firstGeoObject = result.geoObjects.get(0);
														this.apishipCenter.next(
															firstGeoObject.geometry.getCoordinates(),
														);
														this.loading$.next(false);
														cdr.markForCheck();
													},
												});
										},
									});
							}
						},
					});
			}

			if (this.form.controls.fiasData.value && Object.keys(this.form.controls.fiasData.value).length) {
				this.form.controls.address.setValue(this.form.controls.fiasData.value.address!);
				this.fiasSelected = this.form.controls.fiasData.value;
			}

			if (
				this.cartService.cityForm.controls.apishipSelectedPoint.value?.pickPointId &&
				this.cartService.selectedCity!.fiasId &&
				this.checkDeliveryOption(DeliveryType.Apiship)
			) {
				this.loading$.next(true);
				this.cartService
					.getPickupTariffs(
						this.shopId,
						this.cartService.apishipSelectedPoint?.providerKey!,
						this.cartService.selectedCity!.fiasId,
						this.cartService.apishipSelectedPoint?.pickPointId!.toString()!,
					)
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: res => {
							this.loading$.next(false);
							this.cartService.apishipPickupTariffs.next(res);
							if (res.length === 1) {
								this.form.patchValue({ apishipPickupTariff: res[0].tariffId });
							}
							this.calculatePrice(this.cart$.value?.realPrice!);
							this.cdr.detectChanges();
						},
					});
			} else {
				this.cartService.apishipPickupTariffs.next(null);
			}

			if (this.checkDeliveryOption(DeliveryType.PickUp) && this.cartService.selectedCity?.fiasId) {
				this.loading$.next(true);
				this.loadingPickupPoints$.next(true);
				this.cartService
					.getShopDeliveryPickUpPoints(this.shopId, this.cartService.selectedCity.fiasId)
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: res => {
							this.loading$.next(false);
							this.loadingPickupPoints$.next(false);
							if (!res?.length) {
								this.hidePickup = true;
							} else {
								this.pickupPoints.next(res);
								if (res.length === 1) {
									this.cartService.pickupSelectedPoint = res[0];
								}
							}
							this.cdr.markForCheck();
							this.isNoDelivery();
						},
					});
			}

			if (
				this.cartService.cityForm.controls.selectedCity.value &&
				this.checkDeliveryOption(DeliveryType.Apiship)
			) {
				this.loading$.next(true);
			}

			if (
				this.form.controls.address &&
				this.fiasSelected?.isHouse &&
				this.checkDeliveryOption(DeliveryType.Apiship)
			) {
				this.loading$.next(true);
				this.cartService.getDeliveryTariffs(this.shopId, this.fiasSelected.address!).pipe(takeUntil(this.destroy$)).subscribe({
					next: res => {
						this.cartService.deliveryTariffs.next(res);
						if (res.length === 1) {
							this.form.patchValue({ deliveryTariff: res[0].tariffId });
						}
						this.calculatePrice(this.cart$.value?.realPrice!);
						this.loading$.next(false);
						this.cdr.markForCheck();
					},
				});
			}

			if (this.cartService.selectedCity.value !== null) {
				if (this.delivery.deliveryOptions?.length === 1 && !this.checkDeliveryOption(DeliveryType.Apiship)) {
					this.form.controls.deliveryOption.setValue(this.delivery.deliveryOptions[0]);
					this.cdr.markForCheck();
					return;
				}
				if (this.checkDeliveryOption(DeliveryType.Delivery)) {
					this.form.controls.deliveryOption.setValue(DeliveryType.Delivery);
					this.cdr.markForCheck();
					return;
				}
				if (this.checkDeliveryOption(DeliveryType.PickUp)) {
					this.form.controls.deliveryOption.setValue(DeliveryType.PickUp);
					this.cdr.markForCheck();
					return;
				}
				if (this.checkDeliveryOption(DeliveryType.Apiship) && this.cartService.apishipProviders?.value?.providers?.length) {
					this.form.controls.deliveryOption.setValue(this.cartService.apishipProviders.value!.providers![0].providerKey!);
					this.cdr.markForCheck();
					return;
				}
			}
		});

		if (!!this.form.controls.fiasData.value) {
			this.form.controls.address.setValue(this.form.controls.fiasData.value.address!);
			this.fiasSelected = this.form.controls.fiasData.value;
		}

		this.form.controls.deliveryTariff.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				this.calculatePrice(this.cart$.value?.realPrice || 0);
			},
		});

		this.form.controls.address.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				if (this.fiasSelected?.isHouse && this.checkDeliveryOption(DeliveryType.Apiship)) {
					this.loading$.next(true);
					this.cartService.getDeliveryTariffs(this.shopId, this.fiasSelected.address!).pipe(takeUntil(this.destroy$)).subscribe({
						next: res => {
							this.cartService.deliveryTariffs.next(res);
							if (res.length === 1) {
								this.form.patchValue({ deliveryTariff: res[0].tariffId });
							}
							this.loading$.next(false);
							this.cdr.markForCheck();
						},
					});
				}
			},
		});

		this.form.controls.deliveryOption.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				this.cartService.apishipSelectedPoint = null;
				this.cartService.pickupSelectedPoint = null;
				this.form.patchValue({ deliveryTariff: null, apishipPickupTariff: null });
				this.deliveryErr = false;

				if (this.isSelectedDeliveryOptionApiship()) {
					this.loading$.next(true);
					this.cartService.apishipSelectedPoint = null;
					this.cartService
						.getApishipPickupPoints(this.shopId, res!, this.cartService.selectedCity.fiasId)
						.pipe(takeUntil(this.destroy$))
						.subscribe({
							next: data => {
								this.apishipPoints = data;
								this.yaGeocoderService
									.geocode(
										this.cartService.selectedCity.address ??
											this.cartService.selectedCity.addressShort,
									)
									.pipe(takeUntil(this.destroy$))
									.subscribe({
										next: (result: any) => {
											const firstGeoObject = result.geoObjects.get(0);
											this.apishipCenter.next(firstGeoObject.geometry.getCoordinates());
											this.loading$.next(false);
											cdr.markForCheck();
										},
									});
							},
						});
				}

				if (res === DeliveryType.PickUp) {
					this.loading$.next(true);
					this.loadingPickupPoints$.next(true);
					this.cartService
						.getShopDeliveryPickUpPoints(this.shopId, this.cartService.selectedCity.fiasId)
						.pipe(takeUntil(this.destroy$))
						.subscribe({
							next: res => {
								this.loading$.next(false);
								this.loadingPickupPoints$.next(false);
								if (!res?.length) {
									this.hidePickup = true;
								} else {
									this.pickupPoints.next(res);
									if (res.length === 1) {
										this.cartService.pickupSelectedPoint = res[0];
									}
								}
								this.cdr.markForCheck();
								this.isNoDelivery();
							},
						});
				}

				if (!(res === DeliveryType.Delivery)) {
					this.calculatePrice(this.cart$.value?.realPrice || 0, true);
				}
			},
		});

		this.form.get('apishipPickupTariff')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				this.calculatePrice(this.cart$.value?.realPrice || 0);
				this.tarrifErr = false;
				this.cdr.detectChanges();
			},
		});

		this.form.controls.paymentType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				this.cartService.getBasket(this.shopId, this.form.controls.paymentType.value).pipe(takeUntil(this.destroy$)).subscribe(cart => {
					this.cartService.productsInCart$.next(cart);
					this.finalPrice.next(this.finalPrice.value);
					this.calculatePrice(this.cart$.value?.realPrice || 0);
				});
				this.cdr.markForCheck();
			},
		});

		this.finalPrice.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				if (cartService.isWithoutPayment || this.shopInfo.paymentInfo.customPayments.find((i: any) => i.id === this.form.controls.paymentType.value)) {
					window.Telegram.WebApp.MainButton.setText('Оформить заказ');
				} else {
					this.translocoService.selectTranslateObject('order').pipe(takeUntil(this.destroy$)).subscribe(t => {
						window.Telegram.WebApp.MainButton.setText(
							`${t['buy']} ${res?.toFixed(2)?.toLocaleString()}` +
								' ' +
								`${this.shopService.shopMoneySign.value}`,
						);
					});
				}
				this.cdr.markForCheck();
			},
		});

		this.loading$.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				this.showBtn = !res;
				this.cdr.markForCheck();
			},
		});

		this.cartService.cityForm.controls.selectedCity.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				this.cityErr = false;
				if (res.fiasId && this.checkDeliveryOption(DeliveryType.Apiship)) {
					this.loading$.next(true);
					this.loadingProviders$.next(true);
					this.cartService.getApishipProviders(this.shopId, res.fiasId).pipe(takeUntil(this.destroy$)).subscribe({
						next: res => {
							this.cartService.apishipProviders.next(res);
							this.loading$.next(false);
							this.loadingProviders$.next(false);
							this.isNoDelivery();
						},
					});
				} else {
					this.loading$.next(false);
				}
			},
		});

		this.cartService.cityForm.controls.apishipSelectedPoint.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				if (res && res.pickPointId && this.checkDeliveryOption(DeliveryType.Apiship)) {
					this.loading$.next(true);
					this.cartService
						.getPickupTariffs(
							this.shopId,
							this.cartService.apishipSelectedPoint?.providerKey!,
							this.cartService.selectedCity!.fiasId,
							this.cartService.apishipSelectedPoint?.pickPointId!.toString()!,
						)
						.pipe(takeUntil(this.destroy$))
						.subscribe({
							next: res => {
								this.loading$.next(false);
								this.cartService.apishipPickupTariffs.next(res);
								if (res.length === 1) {
									this.form.patchValue({ apishipPickupTariff: res[0].tariffId });
								}
								this.cdr.detectChanges();
							},
						});
				}
			},
		});



		this.cart$.pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				// this.calculatePrice(this.cart$.value.realPrice || 0)
			}
		})

		const BackButton = window.Telegram.WebApp.BackButton;
		BackButton.show();
	}

	get isHasCustomPayment() {
		return this.shopInfo.paymentInfo.paymentTypes.find((i: any) => i === OrderCreatedResponse.paymentType.CUSTOM) && this.shopInfo.paymentInfo.customPayments.length > 0;
	}

	hasPaymentType(type: string): boolean {
		return this.shopInfo.paymentInfo.paymentTypes.find((i: any) => i === type);
	}

	get selectedDeliveryOption(): DeliveryType | string | null {
		return this.form.controls.deliveryOption.value;
	}

	setDeliveryType(deliveryOption: DeliveryType) {
		this.form.patchValue({ deliveryOption });
	}

	sendNotify(shopId: string, orderId: string) {
		this.cartService.sendNotify(shopId, orderId).pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {},
		});
	}

	checkDeliveryOption(deliveryType: DeliveryType): boolean {
		return this.delivery?.deliveryOptions?.includes(deliveryType) ?? false;
	}

	isSelectedDeliveryOptionApiship() {
		return this.cartService.apishipProviders.value?.providers?.some(
			(provider: any) => provider.providerKey === this.form.controls.deliveryOption.value,
		);
	}

	isSelectedDeliveryOptionDelivery() {
		return this.form.controls.deliveryOption.value === DeliveryType.Delivery;
	}

	clearSelectedApishsipPoint() {
		this.cartService.apishipSelectedPoint = null;
		this.form.controls.deliveryOption.setValue(this.form.controls.deliveryOption.value);
	}

	clearSelectedPickupPoint() {
		this.cartService.pickupSelectedPoint = null;
		this.form.controls.deliveryOption.setValue(this.form.controls.deliveryOption.value);
	}

	isNoDelivery() {
		if (this.checkDeliveryOption(DeliveryType.Delivery)) {
			return false;
		}
		if (
			this.checkDeliveryOption(DeliveryType.Apiship) &&
			this.cartService.apishipProviders.value?.providers?.length === 0 &&
			!this.cartService.apishipProviders.value?.minimalCostDeliveryToDoor
		) {
			return !(this.checkDeliveryOption(DeliveryType.PickUp) && this.pickupPoints.value?.length);
		}
		if (
			this.checkDeliveryOption(DeliveryType.Apiship) &&
			(this.cartService.apishipProviders.value?.providers?.length ||
				this.cartService.apishipProviders.value?.minimalCostDeliveryToDoor)
		) {
			return false;
		}
		return (
			this.checkDeliveryOption(DeliveryType.PickUp) &&
			(this.pickupPoints.value === null || this.pickupPoints.value?.length === 0)
		);
	}

	errors(): any {
		const err: any = FormUtils.getControlErrors(this.form)!;

		if (this.form.value.deliveryOption !== DeliveryType.Delivery || this.shopService.shopMoneySign.value !== '₽') {
			delete err?.address;
		}

		if (this.form.value.deliveryOption !== DeliveryType.PickUp) {
			delete err?.pickUpPointId;
		}
		return err;
	}

	showAdress = false;

	focusAdress = () => {
		this.showAdress = true;
	};

	blurAdress = () => {
		this.showAdress = false;
	};

	activatePromocode = () => {
		this.promocodeErrorMessage = null;
		this.cartService.refreshCart(this.shopId, this.form.controls.paymentType.value);
		if (this.form.value.promocodeId !== '') {
			this.loadingPromocode$.next(true);
			this.shopService.checkPromocode(this.shopId, this.form.value.promocodeId!.toString()).pipe(takeUntil(this.destroy$)).subscribe({
				next: (res: any) => {
					this.isPromocodeActive = true;
					this.cartService.promocodeId = res!.id;
					this.loadingPromocode$.next(false);
					this.cartService.refreshCart(this.shopId, this.form.controls.paymentType.value);
					this.cartService.getBasket(this.shopId, this.form.controls.paymentType.value).pipe(takeUntil(this.destroy$)).subscribe(cart => {
						this.cartService.productsInCart$.next(cart);
						this.finalPrice.next(this.finalPrice.value);
						this.calculatePrice(this.cart$.value?.realPrice || 0);
						this.cdr.markForCheck();
					});
				},
				error: err => {
					this.promocodeErrorMessage = err.error.message;
					this.loadingPromocode$.next(false);
					this.cdr.markForCheck();
				},
			});
		}
	};

	cancelPromocode = () => {
		this.form.patchValue({ promocodeId: '' });
		this.cartService.promocodeId = null;
		this.isPromocodeActive = false;
		this.cartService.refreshCart(this.shopId, this.form.controls.paymentType.value);
		this.cdr.markForCheck();
	};

	openInvoice = (): void => {
		this.loading$.next(true);
		let stop = false;
		if (this.shopService.isShopDigital()) {
			this.form.patchValue({ deliveryOption: OrderRequest.deliveryOption.NONE });
		}
		if (this.form.controls.deliveryOption.value === null) {
			this.deliveryErr = true;
			const el = document.getElementById("type");
			el?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
			stop = true;
		}
		if (!this.shopService.isShopDigital() && this.cartService.selectedCity === null) {
			this.cityErr = true;
			const el = document.getElementById("city");
			el?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
			stop = true;
		}

		if (this.form.controls.deliveryOption.value === DeliveryType.PickUp && !this.shopService.isShopDigital()) {
			this.form.patchValue({ pickUpPointId: this.cartService.pickupSelectedPoint?.id! });
		}
		if (this.shopService.shopMoneySign.value !== '₽') {
			this.form.patchValue({ deliveryOption: OrderRequest.deliveryOption.DELIVERY });
		}
		if (
			this.shopService.shopMoneySign.value === '₽' &&
			(this.form.value.deliveryOption === DeliveryType.Delivery ||
				this.form.value.deliveryOption === DeliveryType.Apiship) &&
			!this.shopService.isShopDigital()
		) {
			if (!this.fiasSelected) {
				this.form.controls['address'].setErrors({ 'fias_empty': true });
				const el = document.getElementById("origin");
				el?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
				stop = true;
			}
			if (!this.fiasSelected?.isHouse) {
				const el = document.getElementById("origin");
				el?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
				if (!stop) {
					this.form.controls['address'].setErrors({ 'fias_house': true });
					stop = true;
				}
			}
			this.cdr.markForCheck();
		}
		if (Object.keys(this.errors() === null ? {} : this.errors()).length) {
			let scrollTo = null;
			this.form.markAllAsTouched();
			this.cdr.markForCheck();
			stop = true;

			if (this.errors()?.receiver?.email?.required) {
				scrollTo = "email";
			}

			if (this.errors()?.receiver?.phone?.required) {
				scrollTo = "phone";
			}

			if (this.errors()?.receiver?.firstName?.required) {
				scrollTo = "firstName";
			}

			if (this.errors()?.receiver?.lastName?.required) {
				scrollTo = "lastName";
			}

			if (this.errors()?.receiver?.patronymic?.required) {
				scrollTo = "patronymic";
			}

			if (scrollTo !== null) {
				const el = document.getElementById(scrollTo);
				el?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
			}
		}

		if (stop) {
			this.loading$.next(false);
			return;
		}
		const data: OrderData = this.form.value! as OrderData;


		if (
			this.form.controls.deliveryOption.value === OrderRequest.deliveryOption.APISHIP &&
			!this.shopService.isShopDigital()
		) {
			const tariff = this.cartService.deliveryTariffs.value!.find(
				item => item.tariffId === this.form.controls.deliveryTariff.value,
			);
			data.apishipDelivery = {
				providerKey: tariff!.providerKey!,
				productDeliveryType: productDeliveryType.DELIVERY_TO_DOOR,
				tariffId: tariff!.tariffId!,
				deliveryCost: tariff!.deliveryCost!,
			};
		}
		if (this.isSelectedDeliveryOptionApiship() && !this.shopService.isShopDigital()) {
			if (!this.cartService.apishipSelectedPoint) {
				this.mapErr = true;
				const el = document.getElementById("map");
				el?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
				this.loading$.next(false);
				return;
			}

			if (!this.form.controls.apishipPickupTariff.value) {
				this.tarrifErr = true;
				const el = document.getElementById("tarrif");
				el?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
				this.loading$.next(false);
				return;
			}

			const tariff = this.cartService.apishipPickupTariffs.value!.find(
				item => item.tariffId === this.form.controls.apishipPickupTariff.value,
			);
			data.apishipDelivery = {
				providerKey: this.cartService.apishipSelectedPoint?.providerKey,
				productDeliveryType: productDeliveryType.DELIVERY_TO_PVZ,
				tariffId: tariff!.tariffId!,
				deliveryCost: tariff!.deliveryCost!,
				pickPointId: this.cartService.apishipSelectedPoint?.pickPointId,
				pickUpPointAddress: this.cartService.apishipSelectedPoint?.address,
			};
			data.deliveryOption = OrderRequest.deliveryOption.APISHIP;
		}
		if (this.fiasSelected?.address && !this.shopService.isShopDigital()) {
			data.address = this.fiasSelected.address;
		}
		if (this.isPromocodeActive) {
			data.promocodeId = this.cartService.promocodeId!;
		}
		if (this.shopInfo.paymentInfo.customPayments.find((i: any) => i.id === data.paymentType)) {
			data.customPaymentTypeId = data.paymentType;
			data.paymentType = OrderCreatedResponse.paymentType.CUSTOM;
		}
		this.cartService.createOrder(this.shopId, data, this.fiasSelected).pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				this.paymentLink = res.paymentLink!;
				const orderId = res.id;

				if (this.cartService.isWithoutPayment || res.paymentType === OrderCreatedResponse.paymentType.CUSTOM) {
					this.cartService.createNoPaymentOrder(this.shopId, res.orderNumber!).pipe(takeUntil(this.destroy$)).subscribe({
						next: res => {
							this.loading$.next(false);
							window.Telegram.WebApp.close();
							return;
						},
						error: err => {
							console.log(err);
							return;
						},
					});
					return;
				}

				if (res.paymentType === OrderCreatedResponse.paymentType.TELEGRAM) {
					window.Telegram.WebApp.openInvoice(this.paymentLink!, (status: InvoiceStatus) => {
						if (status == InvoiceStatus.Failed || status == InvoiceStatus.Cancelled) {
							this.sendNotify(this.shopId, orderId!);
							this.zone.run(() => {
								this.router.navigate([`shops`, this.shopId, 'order-fallback'], {
									queryParams: { order: res.orderNumber, shop: this.shopId },
								});
							});
						}

						if (status == InvoiceStatus.Failed) {
							this.loading$.next(false);
							window.Telegram.WebApp.HapticFeedback.notificationOccurred('error');
						}

						if (status == InvoiceStatus.Cancelled || status == InvoiceStatus.Pending) {
							this.loading$.next(false);
							window.Telegram.WebApp.HapticFeedback.notificationOccurred('warning');
						}

						if (status == InvoiceStatus.Paid) {
							this.loading$.next(false);
							window.Telegram.WebApp.close();
						}
					});
				} else {
					const BackButton = window.Telegram.WebApp.BackButton;
					BackButton.hide();

					this.zone.run(() => {
						window.Telegram.WebApp.openLink(this.paymentLink!);
						window.Telegram.WebApp.close();
					});
				}
			},
			error: err => {
				this.loading$.next(false);
				window.Telegram.WebApp.showAlert('Произошла ошибка. ' + err.error?.message);
			},
		});
	};

	getDadata(s: string) {
		this.cartService.getDeliveryAddress(s, this.cartService.selectedCity!.fiasId).pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				this.fiasList = res;
				this.cdr.markForCheck();
			},
		});
	}

	isDestinationFocused: boolean = false;

	autocompleteFocus() {
		this.isDestinationFocused = true;
	}

	autocompleteBlur() {
		setTimeout(() => {
			this.isDestinationFocused = false;
			if (!this.fiasSelected?.isHouse) {
				this.form.controls['address'].setErrors({ 'fias_house': true });
			}
			if (!this.fiasSelected) {
				this.form.controls['address'].setErrors({ 'fias_empty': true });
			}
			this.cdr.markForCheck();
		}, 100);
	}

	selectEvent(item: AddressSuggestionResponse) {
		this.fiasList = null;
		this.fiasSelected = item;
		this.form.controls['fiasData'].setValue(this.fiasSelected);
		this.form.controls['address'].setErrors({ 'fias': null });
		this.updatingAddress = true;
		this.form.patchValue({ address: item.address });
		this.form.controls['address'].updateValueAndValidity();
		this.cdr.markForCheck();
	}

	onChangeSearch() {
		if (this.updatingAddress) {
			this.updatingAddress = false;
			return;
		}
		if (this.form.controls['address'].value && this.form.controls['address'].value?.length < 3) {
			this.fiasList = null;
			return;
		}
		this.fiasSelected = null;
		this.cartService.deliveryTariffs.next(null);
		this.form.controls['fiasData'].setValue(null);
		this.form.controls['address'].setErrors({ 'fias': null });
		this.form.controls['address'].updateValueAndValidity();
		this.cdr.markForCheck();
		this.getDadata(this.form.value.address!);
	}

	getDeliveryDate(days: number): Date | number {
		const date = new Date();
		return date.setDate(date.getDate() + days);
	}

	openPickUpPoints() {
		this.router.navigate([`shops`, this.shopId, 'pickup-points']);
	}

	async calculatePrice(realPrice: number, reset?: boolean) {
		let finalPrice = realPrice;
		if (this.getDeliveryCost() || reset) {
			const result = await this.cartService.getFullPrice(
				this.shopId,
				reset ? 0 : this.getDeliveryCost(),
				this.isPromocodeActive ? this.cartService.promocodeId : null,
				this.shopInfo.paymentInfo.customPayments.find((i: any) => i.id === this.form.controls.paymentType.value) ? this.form.controls.paymentType.value : null)

			finalPrice = result.realPrice;
		}
		this.finalPrice.next(finalPrice);
		this.cdr.markForCheck();
		this.cdr.detectChanges();
		return finalPrice;
	}

	getDeliveryCost(): number {
		if (
			this.selectedDeliveryOption === DeliveryType.PickUp ||
			this.shopService.isShopDigital() ||
			(!this.checkDeliveryOption(DeliveryType.Apiship) && !this.checkDeliveryOption(DeliveryType.Delivery))
		) {
			return 0;
		}

		if (this.isSelectedDeliveryOptionApiship() && this.cartService.apishipSelectedPoint) {
			return this.cartService.apishipPickupTariffs.value?.find(
				tariff => tariff.tariffId === this.form.controls.apishipPickupTariff.value,
			)?.deliveryCost!;
		}

		if (this.selectedDeliveryOption === DeliveryType.Delivery) {
			if (
				this.delivery?.deliveryCost &&
				this.delivery?.minOrderCostForFreeDelivery &&
				this.cart$.value?.realPrice &&
				this.cart$.value?.realPrice < this.delivery?.minOrderCostForFreeDelivery
			) {

				return this.delivery?.deliveryCost;
			} else {
				return 0;
			}
		}

		if (
			this.selectedDeliveryOption === DeliveryType.Apiship &&
			this.cartService.apishipProviders.value?.minimalCostDeliveryToDoor &&
			this.form.controls.deliveryTariff.value
		) {
			return this.cartService.deliveryTariffs.value?.find(
				tariff => tariff.tariffId === this.form.controls.deliveryTariff.value,
			)?.deliveryCost!;
		}

		return 0;
	}

	// map
	onMapReady({ target }: YaReadyEvent<ymaps.Map>): void {
		this.pickupPoints.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				if (this.form.controls.deliveryOption.value === DeliveryType.PickUp) {
					this.yaGeocoderService
						.geocode(this.cartService.selectedCity.address ?? this.cartService.selectedCity.addressShort)
						.pipe(takeUntil(this.destroy$))
						.subscribe({
							next: (result: object) => {
								const firstGeoObject = (result as any).geoObjects.get(0);

								if (this.cartService.selectedCity?.fiasId === "0c5b2444-70a0-4932-980c-b4dc0d3f02b5") {
									target.setZoom(9);
								} else {
									target.setZoom(10);
								}
								target.setCenter(firstGeoObject.geometry.getCoordinates());

								this.loading$.next(false);
								this.cdr.markForCheck();
							},
						});
					this.createCustomPoints(this.pickupPoints.value!, target);
				}
			},
		});

		this.apishipCenter.pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				if (this.isSelectedDeliveryOptionApiship()) {
					res !== null && target.setCenter(res);
					this.createPoints(this.apishipPoints!, target);
				}
			},
		});

		target.events.add('selectPoint', data => {
			if (this.form.controls.deliveryOption.value === DeliveryType.PickUp) {
				this.cartService.pickupSelectedPoint = data.get('pointData');
			} else {
				this.cartService.apishipSelectedPoint = data.get('pointData');
			}
			this.mapErr = true;
			setTimeout(() => {
				target.controls.get('fullscreenControl').exitFullscreen();
				this.cdr.detectChanges();
			}, 10);
		});
	}

	createPoints(apishipPoints: ApishipPickUpPointsResponse[], map: ymaps.Map) {
		const placemarks: PlacemarkConstructor[] = [];
		let myBalloonFooterBodyLayout = ymaps.templateLayoutFactory.createClass(
			'<input type="button" value="Выбрать" id="balloon-button">',
			{
				build: function () {
					myBalloonFooterBodyLayout.superclass.build.call(this);
					document.getElementById('balloon-button')!.addEventListener('click', function () {
						map.events.fire('selectPoint', { pointData: '' });
					});
				},
			},
		);

		apishipPoints?.forEach(point => {
			placemarks.push({
				geometry: [point.latitude!, point.longitude!],
				properties: {
					balloonContent: '123',
				},
				options: {
					iconColor: '#2aabee',
					balloonContentLayout: ymaps.templateLayoutFactory.createClass(
						'<div class="balloon__name">' +
							point.name +
							'</div> ' +
							'<div class="balloon__address">' +
							point.address +
							'</div> ' +
							'<div class="balloon__time">' +
							(point.timetable ? point.timetable![0] : '') +
							'</div>' +
							'<input type="button" class="balloon__btn" value="Заберу отсюда" id="balloon-button">',
						{
							build: function () {
								myBalloonFooterBodyLayout.superclass.build.call(this);
								document.getElementById('balloon-button')!.addEventListener('click', function () {
									map.events.fire('selectPoint', { pointData: point });
								});
							},
						},
					),
				},
			});
		});
		this.mapPoints = placemarks;
		this.cdr.markForCheck();
	}

	createCustomPoints(points: PickUpPointResponse[], map: ymaps.Map) {
		const placemarks: PlacemarkConstructor[] = [];
		let myBalloonFooterBodyLayout = ymaps.templateLayoutFactory.createClass(
			'<input type="button" value="Выбрать" id="balloon-button">',
			{
				build: function () {
					myBalloonFooterBodyLayout.superclass.build.call(this);
					document.getElementById('balloon-button')!.addEventListener('click', function () {
						map.events.fire('selectPoint', { pointData: '' });
					});
				},
			},
		);

		points?.forEach(point => {
			placemarks.push({
				geometry: [point.address!.latitude!, point.address!.longitude!],
				properties: {
					balloonContent: '123',
				},
				options: {
					iconColor: '#2aabee',
					balloonContentLayout: ymaps.templateLayoutFactory.createClass(
						'<div class="balloon__name">' +
							point.pickUpPointName +
							'</div> ' +
							'<div class="balloon__address">' +
							point.address?.addressFull +
							'</div> ' +
							'<div class="balloon__time"></div><input type="button" class="balloon__btn" value="Заберу отсюда" id="balloon-button">',
						{
							build: function () {
								myBalloonFooterBodyLayout.superclass.build.call(this);
								document.getElementById('balloon-button')!.addEventListener('click', function () {
									map.events.fire('selectPoint', { pointData: point });
								});
							},
						},
					),
				},
			});
		});
		this.mapPoints = placemarks;
		this.cdr.markForCheck();
	}

	ngOnDestroy() {
		this.form.patchValue({ promocodeId: '' });
		this.cartService.promocodeId = null;
		this.isPromocodeActive = false;
		this.cartService.refreshCart(this.shopId, this.form.controls.paymentType.value);
	}

	onPromocodeChange() {
		if (this.form.value.promocodeId == '') {
			this.promocodeErrorMessage = null;
			this.cartService.promocodeId = null;
			this.isPromocodeActive = false;
			this.cartService.refreshCart(this.shopId, this.form.controls.paymentType.value);
			this.cdr.markForCheck();
		}
	}

	isFieldEnabled(name: string) {
		const field = this.fields.value?.find((i: any) => i.name === name);

		if (!field) {
			return false;
		}

		return field?.enabled;
	}

	getProviderName(providerKey: string): string {
		if (providerKey === 'rupost') {
			return 'Почта России';
		}

		if (providerKey === 'rutaxi') {
			return 'Я.Доставка';
		}

		if (providerKey === 'cdek') {
			return 'CДЭК';
		}

		return providerKey;
	}

	protected readonly Number = Number;
	protected readonly OrderRequest = OrderRequest;
	protected readonly OrderCreatedResponse = OrderCreatedResponse;
}
